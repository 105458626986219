import { Col, Row } from "antd"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import React, { useMemo } from "react"
import ContactUs from "../../components/contactus"
import FAQ from "../../components/faq"
import HomePage from "../../components/homepage"
import "../../styles/magnus-app.sass"
import GraphIcon from "../../images/magnus-app-features-graph.svg"
import NotificationBell from "../../images/magnus-app-features-notification.svg"
import CurrentLevel from "../../images/magnus-app-features-current-level.svg"
import Target from "../../images/target.svg"
import Bolt from "../../images/bolt.svg"
import CustomerEngagement from "../../images/customer-engagement.svg"
import useBreakpoint, { smallScreens } from "../../utils/useBreakpoint"

import faqContent from "../../resources/app-faq-content.json"

const styles = {
  imageWrapperStyle: { position: "initial" },
}

const IndexPage = ({ data }) => {
  const { breakpoint, isSmallScreen: reverseOrder } = useBreakpoint()
  const isSmallScreen = breakpoint === "sm" || breakpoint === "xs"
  const heroSectionClasses = React.useMemo(() => {
    const baseClasses = ["magnus-app-info-preview"]
    if (isSmallScreen) {
      baseClasses.push("magnus-app-info-preview-mobile")
    }
    return baseClasses.join(' ')
  }, [isSmallScreen])
  return (
    <HomePage>
      <div className="magnus-app-info-container">
          <section className="magnus-app-info">
            <div className={heroSectionClasses}>
              <div className="magnus-app-info-preview-image">
                <Img
                  imgStyle={isSmallScreen ? {
                    objectPosition: 'left'
                  } : undefined}
                  style={styles.imageWrapperStyle}
                  fluid={isSmallScreen ? data.magnusAppPreviewMobileHeroSection. childImageSharp.fluid : data.magnusAppPreview.childImageSharp.fluid}
                  alt="magnus-app-info-preview-1"
                />
              </div>
              <div className="magnus-app-info-preview-title">
                <h1>magnus</h1>
                <h2>mobile app</h2>
              </div>
            </div>
            <div className="magnus-app-info-text">
              <p>
                Your customers get their monitored level data at their
                fingertips from anywhere and at any time. MAGNUS analyses data
                to provide benchmarking against the typical user like them and
                allows customers to easily review their historical usage data.
              </p>
              <p>
                The Magnus Customer App allows you to communicate directly to
                your monitored customers through Push Notifications for
                launching targeted marketing campaigns at a lower cost than
                traditional methods like email, text messages and flyers.
              </p>
              <p>
              Having a monitored customer base can allow staff to concentrate on servicing new customers due to freed up capacity. Overtime, the more monitored customers the fewer existing customer calls which releases phone lines in busy periods to effectively respond to customers.
              </p>
            </div>
          </section>
        <section className="magnus-app-features">
          <div className="magnus-app-features-content">
              <h1 className="magnus-app-features-content-title">Features</h1>
            <Row
              gutter={[0, 90]}
              justify="space-between"
              className="magnus-app-features-content-info"
            >
              <Col
                className="magnus-app-features-content-info-wrapper"
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
              >
                  <div className="magnus-app-features-content-info-item">
                    <img src={CurrentLevel} alt="graph-icon" />
                  </div>
                  <p className="magnus-app-features-content-info-description">
                    Current Level Data
                  </p>
              </Col>
              <Col
                className="magnus-app-features-content-info-wrapper"
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
              >
                  <div className="magnus-app-features-content-info-item">
                    <img src={GraphIcon} alt="graph-icon" />
                  </div>
                  <p className="magnus-app-features-content-info-description">
                    Historical Usage Graphs
                  </p>
              </Col>
              <Col
                className="magnus-app-features-content-info-wrapper"
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
              >
                  <div className="magnus-app-features-content-info-item">
                    <img src={NotificationBell} alt="graph-icon" />
                  </div>
                  <p className="magnus-app-features-content-info-description">
                    Low Level Warnings
                  </p>
              </Col>
            </Row>
          </div>
        </section>
        <section className="magnus-app-benefits">
          <div className="magnus-app-benefits-content">
              <h1 className="magnus-app-benefits-content-title">Benefits</h1>
            <Row
              gutter={[0, 90]}
              justify="space-between"
              className="magnus-app-benefits-content-info"
            >
              <Col
                className="magnus-app-benefits-content-info-wrapper"
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
              >
                  <div className="magnus-app-benefits-content-info-item">
                    <img src={Bolt} alt="bolt" />
                  </div>
                  <p className="magnus-app-benefits-content-info-description">
                    Proactive rather than reactive
                  </p>
              </Col>
              <Col
                className="magnus-app-benefits-content-info-wrapper"
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
              >
                  <div className="magnus-app-benefits-content-info-item">
                    <img src={Target} alt="target" />
                  </div>
                  <p className="magnus-app-benefits-content-info-description">
                    Targeted marketing campaigns
                  </p>
              </Col>
              <Col
                className="magnus-app-benefits-content-info-wrapper"
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
              >
                  <div className="magnus-app-benefits-content-info-item">
                    <img src={CustomerEngagement} alt="bolt" />
                  </div>
                  <p className="magnus-app-benefits-content-info-description">
                    Increased engagement with customers
                  </p>
              </Col>
            </Row>
          </div>
        </section>
      </div>
      <FAQ content={faqContent} />
      <ContactUs />
    </HomePage>
  )
}

export default IndexPage

export const query = graphql`
  query {
    magnusAppPreview: file(
      relativePath: { eq: "magnus-app-preview-main.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    magnusAppPreviewMobileHeroSection: file(
      relativePath: { eq: "magnus-app-page-mobile-hero-image.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
